import React from "react"

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      usernameValue: "",
      passwordValue: "",
    }
  }

  onHandleSubmit(e) {
    e.preventDefault()
  }
  
  render() {
    return (
      <form className="col s12 m6" name={this.props.name} method="POST">
        <div className="row">
          <div className="col s12">
            <div className="input-field">
              <input
                type="email"
                name="username"
                value={this.state.usernameValue}
                onChange={e => {
                  this.setState({ usernameValue: e.target.value })
                }}
              />
              <label>Username</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <div className="input-field">
              <input
                type="password"
                name="password"
                value={this.state.passwordValue}
                onChange={e => {
                  this.setState({ passwordValue: e.target.value })
                }}
              />
              <label>Password</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col s12 submit-btn">
            <button onClick={e => this.onHandleSubmit(e)}>Login</button>
          </div>
        </div>
      </form>
    )
  }
}

export default LoginForm
